import { gql } from 'apollo-boost';

const GetMeta = gql`
  query GetMeta {
    get_meta {
      build
      is_under_maintenance
      announcement
      server_time
      approval_from
      approval_to
      order_cutoff
      under_maintenance_message
      announcements {
        web
      }
    }
  }
`;

export default GetMeta;
