const VERSION = '3.9.1';
const ENV = 'PRODUCTION';
const API_URL = 'https://gi16prcqh8.execute-api.ap-southeast-1.amazonaws.com/prd/graphql';
const BUILD_DATE = '20240618';
const BUILD_STAGES = {
  DEVELOPMENT: 'DEVELOPMENT',
  PRODUCTION: 'PRODUCTION',
  STAGING: 'STAGING',
};

const FIREBASE_CONFIGS = {
  DEVELOPMENT: {
    apiKey: 'AIzaSyDlMMW1JS2W2mLKKdH5Dr2kKHyDxWEPElc',
    authDomain: 'tgp-franchise-portal-staging.firebaseapp.com',
    databaseURL:
      'https://tgp-franchise-portal-staging-default-rtdb.firebaseio.com',
    projectId: 'tgp-franchise-portal-staging',
    storageBucket: 'tgp-franchise-portal-staging.appspot.com',
    messagingSenderId: '206993393721',
    appId: '1:206993393721:web:286d201287b9135236e5e8',
    measurementId: 'G-581HFDHBMD',
  },
  STAGING: {
    apiKey: 'AIzaSyDlMMW1JS2W2mLKKdH5Dr2kKHyDxWEPElc',
    authDomain: 'tgp-franchise-portal-staging.firebaseapp.com',
    databaseURL:
      'https://tgp-franchise-portal-staging-default-rtdb.firebaseio.com',
    projectId: 'tgp-franchise-portal-staging',
    storageBucket: 'tgp-franchise-portal-staging.appspot.com',
    messagingSenderId: '206993393721',
    appId: '1:206993393721:web:286d201287b9135236e5e8',
    measurementId: 'G-581HFDHBMD',
  },
  PRODUCTION: {
    apiKey: 'AIzaSyAJVl1Rv8V4vz-cLkVlsoNXp0r-ki-M1qE',
    authDomain: 'tgp-franchisee-portal.firebaseapp.com',
    databaseURL: 'https://tgp-franchisee-portal.firebaseio.com',
    projectId: 'tgp-franchisee-portal',
    storageBucket: 'tgp-franchisee-portal.appspot.com',
    messagingSenderId: '303458948840',
    appId: '1:303458948840:web:bb8b450940f51c84a71b5d',
    measurementId: 'G-GRWSC91W1K',
  },
};

const INVOICE_PAYMENT_METHODS = [
  {
    id: 'ipg',
    image: '/images/visa_mastercard_thumbnail.png',
    label: 'Robinsons Bank',
    isProduction: true,
    type: 'Credit / Debit Card',
  },
  // {
  //   id: 'paymaya',
  //   image: '/images/paymaya_thumbnail.png',
  //   label: 'Maya',
  //   isProduction: true,
  //   type: 'Maya',
  // },
];

const PAYMENT_METHODS = INVOICE_PAYMENT_METHODS.filter((method) => {
  if (BUILD_STAGES[ENV] === 'DEVELOPMENT') {
    return true;
  }

  return method?.isProduction;
});

const TICKETING_CONFIG = {
  TICKET_API_BASEURL: 'https://testing.growsari.com/api',
  TICKET_API_KEY: '44662dd24ddda0dbf8f03c49fa2e1486',
  TICKET_API_SECRET: 'fb6aa2b953834333ddecf333d500de4dbaccb073',
};

export default {
  API_URL,
  ENV,
  BUILD_DATE,
  VERSION,
  REGEX_ASIS_BILLS: /^73|^74/g,
  FIREBASE_CONFIG: FIREBASE_CONFIGS[ENV],
  PAYMENT_METHODS,
  TICKETING_CONFIG,
};
