import React from 'react'
import { Button } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

const Loader = ({ message, onOk }) => {

  return (
    <div className="full-height absolute-center">
      <div>
        <WarningOutlined style={{ fontSize: 80, display: 'block', marginBottom: 10, color: '#ccc' }} />
        <div style={{ color: '#999' }}>{message}</div>
        {onOk && (
          <center>
            <Button type="primary" onClick={onOk}>Okay</Button>
          </center>
        )}
      </div>
    </div>
  )

}

export default Loader
