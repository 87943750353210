import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const Loader = ({ message }) => {

  return (
    <div className="full-height absolute-center">
        <div>
        <LoadingOutlined style={{ fontSize: 80, display: 'block', marginBottom: 10, color: '#ccc' }} />
        <div style={{ color: '#999' }}>{message}</div>
        </div>
    </div>
  )

}

export default Loader
