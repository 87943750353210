import React, { Component } from 'react'
import { DashOutlined } from '@ant-design/icons'

export default function comp(importComponent) {

  class AsyncComponent extends Component {

    constructor(props) {

      super(props)
      this.state = { component: null }

    }

    async componentDidMount() {

      const component = await importComponent()
      this.setState({ component: component.default || component })

    }

    render() {

      const C = this.state.component
      return C ? <C {...this.props} /> : <center style={{ padding: '20px' }}><DashOutlined /></center>

    }

  }

  return AsyncComponent

}
