import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import GetMeta from '../graphql/GetMeta';
import Error from '../widgets/Error';
import Loader from '../widgets/Loader';
import { underMaintenanceMessage } from '../constants/app';

const Checker = ({ children }) => {
  const query = useQuery(GetMeta);

  if (query.error) {
    return <Error message="Error encountered. Please try again." />;
  }

  if (query.loading) {
    return <Loader />;
  }

  const shouldShowIsUnderMaintenance =
    !!query?.data?.get_meta?.is_under_maintenance;

  if (shouldShowIsUnderMaintenance) {
    const finalUnderMaintenanceMessage =
      query?.data?.get_meta?.under_maintenance_message ||
      underMaintenanceMessage;

    return <Error message={finalUnderMaintenanceMessage} />;
  }

  return children;
};

Checker.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
  ]),
};

Checker.defaultProps = {
  children: () => null,
};

export default Checker;
