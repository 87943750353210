import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloLink } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { createUploadLink } from 'apollo-upload-client';
import firebase from 'firebase/app';
import { FirebaseDatabaseProvider } from '@react-firebase/database';

import ScrollToTop from './scroll_to_top';
import comp from './comp';
import './styles/common.css';
import './styles/app.css';
import config from './config';
import Checker from './core/Checker';
import 'firebase/database';

const { API_URL, FIREBASE_CONFIG: firebaseConfig } = config;

const httpLink = createUploadLink({ uri: API_URL });
const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('@access_token');

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'x-app-version': '3.2.0',
      'x-source': 'WEB',
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const AppView =
  localStorage.getItem('@access_token') === null
    ? comp(() => import('./core/Public'))
    : comp(() => import('./core/App'));

const App = () => {
  return <AppView />;
};

const component = (
  <ApolloProvider client={client}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <FirebaseDatabaseProvider firebase={firebase} {...firebaseConfig}>
      <BrowserRouter>
        <ScrollToTop>
          <Checker>
            <App />
          </Checker>
        </ScrollToTop>
      </BrowserRouter>
    </FirebaseDatabaseProvider>
  </ApolloProvider>
);

ReactDOM.render(component, document.getElementById('root'));
